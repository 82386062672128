<template>
  <b-row>
    <b-col cols="12" lg="6">
      <b-card no-header>
        <template slot="header">
          Mailgun Settings
        </template>
        <b-form-group label="API Key" label-for="apiKey" description="Enter the mailgun api key">
          <b-input-group>
            <b-form-input id="apiKey" type="text" v-model="mailgun.apiKey"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="API Domain" label-for="apiDomain" description="e.g. your.domain.com">
          <b-input-group>
            <b-form-input id="apiDomain" type="text" v-model="mailgun.domain"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="From Email" label-for="fromEmail" description="e.g. email@lifesuccesslegacy.com">
          <b-input-group>
            <b-form-input id="fromEmail" type="text" v-model="mailgun.fromEmail"></b-form-input>
          </b-input-group>
        </b-form-group>
        <template slot="footer">
          <b-row>
            <b-col sm="12" class="text-right">
              <b-button variant="success" @click="save">Save</b-button>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'Mailgun',
  data: () => {
    return {
      mailgun: {
        apiKey: null,
        domain: null,
        fromEmail: null
      }
    }
  },
  methods: {
    fetchConfig () {
      this.$api.configurations.get('mailgun')
        .then((response) => {
          let config = response.data.mailgun || {}
          this.mailgun.apiKey = config.apiKey || null
          this.mailgun.domain = config.domain || null
          this.mailgun.fromEmail = config.fromEmail || null
        })
        .catch((err) => {
          this.$snotify.error('Error fetching configuration')
        })
    },
    save () {
      this.$api.configurations.bulkUpdate({ mailgun: { ...this.mailgun } })
        .then((response) => {
          this.$snotify.success('Mailgun Configuration Saved')
        })
        .catch((err) => {
          this.$snotify.error('Error Saving Configuration')
        })
    }
  },
  created () {
    this.fetchConfig()
  }
}
</script>
